import React from 'react';
import {
    Box,
    Typography,
    Radio, FormControlLabel,
    FormControl,
    IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CannabisController, {
    Props,
} from './CannabisController.web';
import { info, calendar } from './assets';




export const configJSON = require("./config.js");


export default class Cannabis extends CannabisController {
    constructor(props: Props) {
        super(props);

    }
    render() {
        const valuesOptions = [{ value: 'Yes', label: `Yes` }, { value: 'No', label: `No` }]
        const { drinkCount } = this.state;
        return (
            <Box sx={{ position: "relative" }}>

                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <div style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'start',
                        flexWrap: 'wrap',
                    }}>
                        {this.state.options?.map((option: any) => (
                            <Box
                                key={option.value}
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s, border-color 0.3s',
                                    border: this.state.selectedValue === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                    backgroundColor: this.state.selectedValue === option.value ? '#EFF8FB' : 'transparent',
                                    borderRadius: '32px',
                                    padding: '8px 12px',
                                    whiteSpace: 'nowrap',
                                    height: '25px',
                                    cursor: 'pointer'
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={this.state.selectedValue === option.value}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#00A7D1',
                                                },
                                                color: '#E3E3E3'
                                            }}
                                            onChange={this.handleChange}
                                            value={option.value}
                                        />
                                    }
                                    label={
                                        <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                    }
                                />
                            </Box>
                        ))}
                    </div>
                </FormControl>

                {this.state.selectedValue === 'Reduce how much' && (
                    <>
                        <Typography sx={{

                            fontSize: '16px',
                            color: '#252525',
                            marginBottom: '20px',
                            width: '90%',
                            fontFamily: 'Chromatica-Regular',
                            marginTop: '20px',
                            padding: '10px',
                            borderRadius: '16px',
                            backgroundColor: '#F5F5F5'
                        }}>
                            <img
                                src={info.default}
                                alt="info"
                                style={{
                                    position: 'relative',
                                    height: '24px',
                                    top: '6px',
                                    marginRight: '3px',
                                    width: '24px'
                                }} />When you think about reducing, how would you want to measure that? Ounces, $$, hits? [choose one]</Typography>

                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <div style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                            }}>
                                {this.state.subOptions?.map((option: any) => (
                                    <Box
                                        key={option.value}
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            transition: 'background-color 0.3s, border-color 0.3s',
                                            border: this.state.selectedValueSubOption === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                            backgroundColor: this.state.selectedValueSubOption === option.value ? '#EFF8FB' : 'transparent',
                                            borderRadius: '32px',
                                            padding: '8px 12px',
                                            whiteSpace: 'nowrap',
                                            height: '25px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValueSubOption === option.value}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#00A7D1',
                                                        },
                                                        color: '#E3E3E3'
                                                    }}
                                                    onChange={this.handleChangeSubOption}
                                                    value={option.value}
                                                />
                                            }
                                            label={
                                                <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                            }
                                        />
                                    </Box>
                                ))}
                            </div>
                        </FormControl>

                        {this.state.selectedValueSubOption === 'Ounces' &&
                            <>
                                <Typography
                                    sx={{
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        color: '#252525',
                                        marginTop: '30px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    Over the last week, how many Ounces did you have on a typical day when you use cannabis?
                                </Typography>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <IconButton
                                        data-test-id="decrement"
                                        onClick={this.handleDecrement}
                                        sx={{
                                            borderRadius: '50%',
                                            backgroundColor: '#00A7D1',
                                            color: 'white',
                                            width: '40px',
                                            height: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            }
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <input
                                        type="text"
                                        data-test-id="changedrink"
                                        value={drinkCount}
                                        onChange={this.handleInputChange}
                                        style={{
                                           
                                            color: '#252525',
                                            height: '32px',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            width: '51px',
                                            border: '1px solid #E3E3E3',
                                            borderRadius: '4px',
                                            textAlign: 'center',
                                            outline: 'none',

                                        }}
                                       
                                        onFocus={(e) => {
                                            if (e.target.style) {
                                                e.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.style) {
                                                e.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                    />
                                    <IconButton
                                       
                                        onClick={this.handleIncrement}
                                         data-test-id="increment"
                                        sx={{
                                            borderRadius: '50%',
                                           
                                            height: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            },
                                            backgroundColor: '#00A7D1',
                                            color: 'white',
                                            width: '40px',
                                        }}
                                    > <AddIcon />
                                    </IconButton>
                                </div>

                                <Typography sx={{
                                    
                                    color: '#252525',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    marginTop: '30px',
                                    marginBottom: '20px'
                                }}>What would you like to reduce that amount to this week?
                                </Typography>

                                <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                                    <IconButton
                                        onClick={this.handleDecrementSecond}
                                        sx={{
                                            color: 'white',
                                            width: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#00A7D1',
                                            height: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            }
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <input
                                        type="text"
                                        data-test-id="handleinput"
                                        value={this.state.drinkCountSecond}
                                        onChange={this.handleInputChangeSecond}
                                        style={{
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            color: '#252525',
                                            outline: 'none',
                                            fontFamily: 'Chromatica-Regular',
                                            width: '51px',
                                            borderRadius: '4px',
                                            height: '40px',
                                            border: '1px solid #E3E3E3',

                                        }}
                                        onFocus={(eve) => {
                                            if (eve.target.style) {
                                                eve.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                        onBlur={(event) => {
                                            if (event.target.style) {
                                                event.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}

                                    />
                                    <IconButton
                                       
                                        sx={{
                                            height: '40px',
                                            backgroundColor: '#00A7D1',
                                            borderRadius: '50%',
                                            color: 'white',
                                            width: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            },
                                           
                                        }}
                                        onClick={this.handleIncrementSecond}
                                    ><AddIcon />
                                        
                                    </IconButton>
                                </div>
                            </>
                        }
                        {this.state.selectedValueSubOption === '$$' &&
                            <>
                                <Typography
                                    sx={{
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        color: '#252525',
                                        marginTop: '30px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    Over the last week, how many $$ did you have on a typical day when you use cannabis?
                                </Typography>
                                <input
                                    type="text"
                                    id="input1"
                                    placeholder="Type here..."
                                    style={{
                                        width: '286px',
                                        height: '58px',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        border: 'none',
                                        outline: 'none',
                                        marginBottom: '10px',
                                        backgroundColor: 'transparent',
                                        borderBottom: '1px solid #E1E1E1',
                                    }} />


                                <Typography sx={{
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    color: '#252525',
                                    marginTop: '30px',
                                    marginBottom: '20px'
                                }}>
                                    What would you like to reduce that amount to this week?
                                </Typography>
                                <input
                                    type="text"
                                    id="input2"
                                    placeholder="Type here..."
                                    style={{
                                        width: '286px',
                                        height: '58px',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        border: 'none',
                                        outline: 'none',
                                        marginBottom: '10px',
                                        backgroundColor: 'transparent',
                                        borderBottom: '1px solid #E1E1E1',
                                    }} />

                            </>
                        }
                        {this.state.selectedValueSubOption === 'Hits' &&
                            <>
                                <Typography
                                    sx={{
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        color: '#252525',
                                        marginTop: '30px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    Over the last week, how many Hits did you have on a typical day when you use cannabis?
                                </Typography>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <IconButton
                                        data-test-id="decrement"
                                        onClick={this.handleCannabisDecrement}
                                        sx={{
                                            borderRadius: '50%',
                                            backgroundColor: '#00A7D1',
                                            color: 'white',
                                            width: '40px',
                                            height: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            }
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <input
                                        type="text"
                                        data-test-id="changedrink2"
                                        value={this.state.cannabisCount}
                                        onChange={this.handleInputCannabisChange}
                                        style={{
                                            width: '51px',
                                            border: '1px solid #E3E3E3',
                                            borderRadius: '4px',
                                            textAlign: 'center',
                                            outline: 'none',
                                            fontSize: '16px',
                                            height: '32px',
                                            fontFamily: 'Chromatica-Regular',
                                            color: '#252525',
                                        }}
                                        onBlur={(events) => {
                                            if (events.target.style) {
                                                events.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                        onFocus={(e) => {
                                            if (e.target.style) {e.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                    />
                                    <IconButton
                                        
                                        sx={{
                                            borderRadius: '50%',
                                            backgroundColor: '#00A7D1',
                                            width: '40px',
                                            height: '40px',
                                            color: 'white',   
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            }
                                        }}
                                        data-test-id="increment"
                                        onClick={this.handleIncrementCannabis}
                                    >
                                        <AddIcon /></IconButton>
                                    
                                </div>

                                <Typography sx={{
                                      fontSize: '16px',
                                      color: '#252525',
                                      marginTop: '30px',
                                      marginBottom: '20px',
                                    fontFamily: 'Chromatica-Regular',
                                }}> What would you like to reduce that amount to this week?
                                </Typography>
                                <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                                    <IconButton
                                        onClick={this.handleCannabisSecondDec}
                                        sx={{
                                            color: 'white',
                                            width: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#00A7D1',
                                            height: '40px',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            }
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <input
                                        type="text"
                                        data-test-id="handleinput"
                                        value={this.state.CannabisSecond}
                                        onChange={this.handleInputCannabisSecond}
                                        style={{
                                            width: '51px',
                                            borderRadius: '4px',
                                            height: '32px',
                                            fontFamily: 'Chromatica-Regular',
                                            textAlign: 'center',
                                            outline: 'none',
                                            fontSize: '16px',
                                            color: '#252525',
                                            
                                            border: '1px solid #E3E3E3',

                                        }}
                                        onFocus={(eventFocus) => {
                                            if (eventFocus.target.style) {
                                                eventFocus.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}
                                        onBlur={(eventFocus) => {
                                            if (eventFocus.target.style) {
                                                eventFocus.target.style.border = '1px solid #E3E3E3';
                                            }
                                        }}

                                    />
                                    <IconButton
                                        onClick={this.handleCannabisSecondInc}
                                        sx={{
                                           
                                            borderRadius: '50%',
                                            '&:hover': {
                                                backgroundColor: '#0096C7'
                                            },
                                            height: '40px',
                                            backgroundColor: '#00A7D1',
                                            color: 'white',
                                            width: '40px',
                                        }}
                                    ><AddIcon />
                                    </IconButton>
                                </div>
                            </>
                        }
                    </>
                )}
                {this.state.selectedValue === 'Reduce how often' && (
                    <>
                        <Typography
                            sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}
                        >
                            Out of the past 7 days, how many days did you use cannabis?
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <IconButton
                                onClick={this.handleDecrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '40px',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="drinkcountoften"
                                value={this.state.drinkCountOften}
                                onChange={this.handleInputChangeOften}
                                style={{
                                    width: '51px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    color: '#252525',
                                    height: '40px',
                                    textAlign: 'center',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    outline: 'none',

                                }}
                                onFocus={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                onBlur={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                            />
                            <IconButton
                                onClick={this.handleIncrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '40px',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>

                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Out of the NEXT 7 days, how many days do you want to use cannabis?
                        </Typography>

                        <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                            <IconButton
                                data-test-id="secondoften1"
                                onClick={this.handleDecrementSecondOften}
                                sx={{
                                    color: 'white',
                                    width: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="text"
                                value={this.state.drinkCountSecondOften}
                                onChange={this.handleInputChangeSecondOften}
                                style={{
                                    fontFamily: 'Chromatica-Regular',
                                    height: '40px',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    color: '#252525',
                                    width: '51px',
                                    outline: 'none',

                                }}
                                onBlur={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                onFocus={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                            />
                            <IconButton
                                data-test-id="secondoften"
                                onClick={this.handleIncrementSecondOften}
                                sx={{
                                    height: '40px',
                                    backgroundColor: '#00A7D1',
                                    borderRadius: '50%',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    },
                                    color: 'white',
                                    width: '40px',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </>
                )}
                {this.state.selectedValue === 'Safer/healthier' && (
                    <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Check out our list of safer use strategies <span style={{ textDecoration: 'underline', fontFamily: 'Chromatica-Bold' }}>Here</span> to choose your goals for the next week.
                        </Typography>
                    </>
                )}
                {this.state.selectedValue === 'Other' && (
                    <div>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            What do you want to see happen with your cannabis use?
                        </Typography>

                        <input
                            type="text"
                            placeholder="Type here..."
                            style={{
                                width: '816px',
                                height: '145px',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                padding: '10px 0',
                                border: 'none',
                                outline: 'none',
                                marginBottom: '10px',
                                backgroundColor: 'transparent',
                                borderBottom: '1px solid #E1E1E1',
                            }} />
                        <style>
                            {`input::placeholder {color: #939393;font-family: 'Chromatica-Bold'; } `}
                        </style>
                    </div>
                )}

                {this.state.selectedValue === 'Stop completely' && (
                    <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from cannabis at this point?
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            gap: '10px'
                        }}>
                            {valuesOptions?.map((option: any) => (
                                <Box
                                    key={option.value}
                                    sx={{
                                        display: 'inline-flex',
                                        border: this.state.selectedValueOption === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                        backgroundColor: this.state.selectedValueOption === option.value ? '#EFF8FB' : 'transparent',
                                        borderRadius: '32px',
                                        padding: '8px 12px',
                                        alignItems: 'center',
                                        transition: 'background-color 0.3s, border-color 0.3s',
                                        whiteSpace: 'nowrap',
                                        height: '25px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.selectedValueOption === option.value}
                                                onChange={this.handleChangeOption}
                                                value={option.value}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#00A7D1',
                                                    },
                                                    color: '#E3E3E3'
                                                }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                        }
                                    />
                                </Box>
                            ))}
                        </div>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Way to commit to taking a break from cannabis! What day this week do you want to start?
                        </Typography>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                                gap: '10px'
                            }}>
                                {this.state.optionsDate?.map((option: any) => (
                                    <Box
                                        key={option.value}
                                        sx={{
                                            display: 'inline-flex',
                                            border: this.state.selectedValueCalendar === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                            backgroundColor: this.state.selectedValueCalendar === option.value ? '#EFF8FB' : 'transparent',
                                            borderRadius: '32px',
                                            padding: '8px 12px',
                                            alignItems: 'center',
                                            transition: 'background-color 0.3s, border-color 0.3s',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValueCalendar === option.value}
                                                    onChange={this.handleChangeCalendar}
                                                    value={option.value}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#00A7D1',
                                                        },
                                                        color: '#E3E3E3'
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                            }
                                        />
                                    </Box>
                                ))}
                            </div>
                        </FormControl>
                        {this.state.selectedValueCalendar === 'other' && (
                            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                <input
                                    type="text"
                                    style={{
                                        border: 'none',
                                        width: '100%',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        outline: 'none',
                                        marginRight: '10px',
                                        backgroundColor: 'transparent',
                                    }}
                                    placeholder="Choose Day"
                                    value={this.state.selectedDate || ''}
                                    readOnly
                                />
                                <img
                                    src={calendar.default}
                                    alt="Open Calendar"
                                    style={{ height: '44px', cursor: 'pointer', width: '44px', }}
                                />
                            </div>
                        )}
                    </>
                )}
            </Box>
        )
    }
}

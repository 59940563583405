import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep:number;
  activeSteps:number;
  month: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardControllerInformation extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      activeStep:0,
      activeSteps:0,
      month:""
    };

  
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start
 



 
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };
  handleNexts = () => {
    this.setState((prevState) => ({
      activeSteps: prevState.activeSteps + 1,
    }));
  };

  hamburgerMenu(){
    this.props.navigation.navigate("HamburgerMenu")
  }
  goTodashnextScreen(){
    this.props.navigation.navigate("GoalsInformation")
  }
 
  handleChangemonth = (event:any) => {
    this.setState({ month: event.target?.value });
  };

 

 


  // Customizable Area End
}

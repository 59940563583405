import React from 'react';
import {
  Grid,
  Box,
  Typography
} from "@mui/material";
import GoalsController, {
  Props,
} from './GoalsController.web';
import { arrow, image3, rightimage, leftimage, substanceBottom, menuIcon } from './assets';



export const configJSON = require("./config.js");


export default class Goals extends GoalsController {
  constructor(props: Props) {
    super(props);

  }
  render() {
    return (
      <Grid
        item
        style={{
          overflowY:'scroll',
          height:'100%',
          width:'100%',
          backgroundColor: "#F0F0F0",
        }}
      >
        <Grid
          item
          style={{
            height: '88px',
            backgroundColor: '#0F4257',
            margin: '10px',
            borderRadius: '16px',
            width: '98.5%',
          }}
        >
           <Box
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              height: "100%",
              alignContent:'center'

            }}
          >
            <Box
              sx={{
                fontFamily: "Josefin Sans",
                fontSize: "40px",
                color: "#ffffff",
                fontWeight: 300,
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  marginBottom: "4px",
                  color: "#EBFF00",
                  fontSize: "40px",
                  marginLeft: "40px",
                  fontFamily: "Josefin Sans",
                  lineHeight: "40px",
                }}
              >
                Well
              </span>
              Spring
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
                fontSize: "20px",          
              }}
            >
              Hey, Tom Chandler
            </Typography>
            <Box
                 onClick={()=>{this.hamburgerMenu()}}
                  component="img"
                  sx={{       
                    right: "42px",
                    position: "absolute",
                    marginRight: "40px",
                  }}
                  src={menuIcon.default}
                  alt="menuIcon"
                 
                />
          </Box>
        </Grid>

        <Grid
          item
          style={{
          
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '15px',
            width: '98.5%',
            margin: '10px',
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Box onClick={() => this.introductionPage()} sx={{ paddingTop: '30px', cursor: 'pointer' }} data-test-id="back-btn">
            <Box
              component="img"
              src={arrow.default}
              alt="Back Arrow"
              sx={{ position: "absolute", paddingTop: '6px', left: 60 }}
            />
            <Typography
              sx={{
                position: "absolute",
                left: 82,
                cursor: "pointer",
                color:'#252525',
                fontFamily: 'Chromatica-Bold',
              }}
            >
              Back
            </Typography>
          </Box>

          <Box sx={{ position: "relative",  height:'auto',
                    minHeight:'100vh',top: 20, marginLeft: '50px',left:'13px' }}>
            <Box
              component="img"
              src={image3.default}
              alt="Logo Top Left"
              sx={{ width: '120px', height: '180px' }}
            />
            <Typography
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                marginTop: '109px',
                fontFamily: 'Josefin Sans',
                fontWeight: '900',
                fontSize: '32px',
                color: '#00A7D1'
              }}
            >
              Your Goals
            </Typography>
            <Typography sx={{
              fontFamily: 'Chromatica-Regular',
              fontSize: '16px',
              color: '#252525',
              marginBottom: '20px'
            }}
            >
              What do you want to see happen for yourself? Some people call this goals, intentions, or visions.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                overflow: 'hidden',
                width: '91%',
              }}
            >
              <Box
                component="img"
                onClick={() => this.substancePage()}
                src={rightimage.default}
                alt="Right Image"
                sx={{
                  zIndex: 100,
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: '0',
                  maxWidth: '644px',
                  width: '100%',
                  paddingTop: '20px',
                  marginBottom: '100px',
                  cursor: 'pointer',
                }}
              />

              <Box
                component="img"
                onClick={() => this.OtherGoals()}
                src={leftimage.default}
                alt="Left Image"
                sx={{
                  zIndex: 100,
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: '0',
                  maxWidth: '644px',
                  width: '100%',
                  paddingTop: '20px',
                  marginBottom: '100px',
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box
              sx={{
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                px: 4,
              }}
            >
             <Box
                  component="img"  
                  sx={{ 
                    width: '100%',
                    position: "absolute",
                    bottom: 19,
                    right: 0,
                    height: 'auto',
                    maxWidth: '600px',
                  }}
                  alt="bottomSection"
                  src={substanceBottom.default}
                />
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
}


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import dayjs from "dayjs";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  children?: React.ReactNode;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  tobacoMeasureType:string
  tobacoReduceState:string
  cigarettesReduceType:string
  lastWeekCigarettesCount:number
  nextweekCigarettesReduceCount:number
  pastSevenDaysCigarettesCount:number
  nextSevenDaysCigarettesCount:number
  isTakingBreakCigarettes:string,
  tobaccoBreakDay: dayjs.Dayjs[]; 
  tobaccoBreakSelectedDate:string;
  selectedTab:string,
  tobaccoCalendar:boolean,
  tobaccoCalendarSelectedDate:any,
  upperReduceState:string,
  upperMetricValue:string,
  lastWeekUppersOuncesCount:number,
  nextweekUppersOuncessReduceCount:number,
  pastSevenDaysUppersCount:number,
  nextSevenDaysUppersCount:number,
  isTakingBreakUppers:string,
  uppersBreakSelectedDate:string,
  uppersCalendarSelectedDate:any,
  lastWeekUppersHitsCount:number,
  nextweekUppersHitsReduceCount:number
  downersReduceState:any,
  downersMetricValue:string,
  lastWeekDownersOuncesCount:number,
  nextweekDownersOuncessReduceCount:number,
  pastSevenDaysDownersCount:number,
  nextSevenDaysDownersCount:number,
  isTakingBreakDowners:string,
  downersBreakSelectedDate:string
  lastWeekDownersHitsCount:number,
  nextweekDownersHitsReduceCount:number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      tobacoMeasureType:'Others',
      tobacoReduceState:'reduceHowMuch',
      cigarettesReduceType:'Others',
      lastWeekCigarettesCount:0,
      nextweekCigarettesReduceCount:0,
      pastSevenDaysCigarettesCount:0,
      nextSevenDaysCigarettesCount:0,
      isTakingBreakCigarettes:"true",
      tobaccoBreakDay:[],
      tobaccoBreakSelectedDate:'',
      selectedTab:'alcohol',
      tobaccoCalendar:false,
      tobaccoCalendarSelectedDate:null,
      upperReduceState:'UppersReduceHowMuch',
      upperMetricValue: 'ounces',
      lastWeekUppersOuncesCount:0,
      nextweekUppersOuncessReduceCount:0,
      pastSevenDaysUppersCount:0,
      nextSevenDaysUppersCount:0,
      isTakingBreakUppers:"yes",
      uppersBreakSelectedDate:'',
      uppersCalendarSelectedDate:null,
      lastWeekUppersHitsCount:0,
      nextweekUppersHitsReduceCount:0,
      downersReduceState:"downersReduceHowMuch",
      downersMetricValue: 'ounces',
      lastWeekDownersOuncesCount:0,
      nextweekDownersOuncessReduceCount:0,
      pastSevenDaysDownersCount:0,
      nextSevenDaysDownersCount:0,
      isTakingBreakDowners:'yes',
      downersBreakSelectedDate:'',
      lastWeekDownersHitsCount:0,
      nextweekDownersHitsReduceCount:0
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const today = dayjs();
    const upcomingDates = Array.from({ length: 5 }, (_, index) => today.add(index, 'day'));
    this.setState({ tobaccoBreakDay: upcomingDates ,tobaccoBreakSelectedDate: dayjs().format('YYYY-MM-DD'),uppersBreakSelectedDate: dayjs().format('YYYY-MM-DD'),downersBreakSelectedDate:dayjs().format('YYYY-MM-DD')});   
  }









  handleCountChange = (key: keyof S, value: number) => {
    this.setState((prevState) => {
      const currentCount = prevState[key] as number;
      if (currentCount === 0 && value < 0) {
        return null;
      }
  
      return {
        [key]: currentCount + value
      } as unknown as Pick<S, keyof S>})
  }
  handleCigarettesReduceTypeChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target; 
        this.setState({ [name]: value }as Pick<S, 'cigarettesReduceType'>);
  };
  handleTobaccoBreakDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ tobaccoBreakSelectedDate: event.target.value });
  };
  handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({
      selectedTab:newValue
    });
  };

  handleUppersBreakDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ uppersBreakSelectedDate: event.target.value });
  };

    moveToUppers = () => {
    this.setState({selectedTab:"uppers"})
  }

  substanceGoals(){
    this.props.navigation.navigate("SubstanceGoals")
  }
  hamburgerMenu(){
    this.props.navigation.navigate("HamburgerMenu")
  }

  handleDownersBreakDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ downersBreakSelectedDate: event.target.value });
  };


  moveToDowners = () => {
    console.log(this.state,'stateeee')
    this.setState({selectedTab:"downers"})
  }

  moveToOthers = () => {
    this.setState({selectedTab:"Others"})
  }
  
  // Customizable Area End
}

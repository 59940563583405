import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import dayjs from 'dayjs';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkedItems:any,
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet:any,
  openCalender: string,
  openTime:boolean
  selectedDate:string,
  options: any,
  selectedValue:any,
  // Customizable Area End
}

interface SS {
  id: any;
}
interface NumberIndexedObject {
  [key: string]: string;
}

export default class GoalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      checkedItems:{},
      options: [], 
      newDateData: "",
      timeSet:'',
      currDate: {},
      openCalender: "",
      openTime:false,
      selectedDate:"",
      selectedValue:'',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.generateOptions();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  introductionPage(){
    this.props.navigation.navigate("Categoriessubcategories")

  }
  OtherGoals(){
     this.props.navigation.navigate("OtherGoals")
  }
  hamburgerMenu(){
    this.props.navigation.navigate("HamburgerMenu")
  }
 
  formatDate(date:any) {
    const dateMoment = moment(date); 
    const formattedDate = dateMoment.format('ddd, D MMMM'); 
    const isToday = dateMoment.isSame(moment(), 'day'); 
    return isToday ? formattedDate.replace(',', '') : formattedDate;
}

  generateOptions() {
    const today = new Date(); 
    const options = [
        { value: 'today', label: `Today, ${this.formatDate(today)}` },
    ];
    for (let i = 1; i <= 4; i++) {
        const nextDate = new Date(today); 
        nextDate.setDate(today.getDate() + i);
        options.push({
            value: `nextDay${i}`,
            label: `${this.formatDate(nextDate)}`,
        });
    }
    options.push({ value: 'other', label: 'Other Day' });
    this.setState({ options });
}
  handleChange = (event:any) => {
    this.setState({ selectedValue: event.target.value });
};
goalsPage(){
  this.props.navigation.navigate("Goals")
}

openPopUp(key: number) {
  this.setState({
    openCalender: key.toString()
  });
}
openTimer() {
  this.setState({
    openTime: true
  });
}

closePopUp() {
  this.setState({
    openCalender: ""
  });
}

  handleCheckboxChange = (id:any) => {
    this.setState((prevState) => ({
        checkedItems: {
            ...prevState.checkedItems,
            [id]: !prevState.checkedItems[id],
        },
    }));
  }
  substancePage(){
    this.props.navigation.navigate("SubstanceGoals")
  }
  convertToDayjsDate(dateString:string) {
    if (!dateString) return null; 
    return dayjs(dateString, 'DD/MM/YYYY'); 
  }
  handleDate(e: any) {
    const date = dayjs.isDayjs(e) ? e : dayjs(e);
    if (date.isValid()) {
      let finalDate = date.format("DD/MM/YYYY"); 
      this.setState({
        newDateData: finalDate
      });
    } 
  }
  handleTime(e:any){
    this.setState({
      timeSet: e,
      openTime: false
  });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  handlePopupTime(keyAnswer: any) {
    if (keyAnswer) {return true;} else {
      return false;
    }
  }
  customForm(){
    this.props.navigation.navigate("CustomForm")
  }
  confirmCal() {
    let data = { ...this.state.currDate };
    data[this.state.openCalender] = this.state.newDateData;
    this.setState({
      currDate: { ...data },
      openCalender: "",
      selectedDate: this.state.newDateData
    },
     
    );
  }

  // Customizable Area End
}

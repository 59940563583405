import React from 'react';
import {
    Grid,
    Button,
    Box,
    Checkbox,
    Typography
} from "@mui/material";

import CategoriessubController, {
    Props,
} from './CategoriessubController.web';
import { arrow, bottomBannerImg,imagealcohal,backwardbutton, arrowright, one, rightmovearrow, hamburgermenu } from './assets';


export const configJSON = require("./config.js");


export default class Categoriessubcategories extends CategoriessubController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        const { scrollOffset } = this.state;
        const visibleItems = this.state.gridItems.slice(scrollOffset, scrollOffset + 5);
        const { gridItems, checkedItems } = this.state;
        return (
            <Grid
            item
            style={{
             overflowY:'scroll',backgroundColor: "#F0F0F0", height:'100%',
            }}
          >
            <Grid
              item
              style={{
                height: '88px',
                backgroundColor: '#0F4257',
                margin: '10px',
                borderRadius: '16px',
                width: '98.5%', 
              }}
            >
             <Box style={webStyle.boxheader}
          >
            <Box
              style={webStyle.arrow}
            >
              <span
                style={webStyle.span}
              >
                Well
              </span>
              Spring
            </Box>
            <Typography
             style={webStyle.name}
            >
              Hey, Tom Chandler
            </Typography>
                <Box
                 onClick={()=>{this.hamburgerPage()}}
                  component="img"
                  src={hamburgermenu.default}
                  alt="menuIcon"
                  sx={{
                    position: "absolute",
                    right: "42px",
                    marginRight: "40px",
                  }}
                />

          </Box>
            </Grid>
              <Grid
                item
                style={{...webStyle.gridItem, position: 'relative'}}>
                <Box sx={{ paddingTop: '30px' , cursor: 'pointer' }}  onClick={()=>this.loginPage()} data-test-id="back-btn">
                  <Box      
                    sx={{ paddingTop: '6px', position: "absolute", left: 60}}
                    component="img"
                    src={arrow.default}
                    alt="Back Arrow"
                  />
                  <Typography
                    style={{...webStyle.backbutton, position: "absolute"}}
                  >
                    Back
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    top: 20,
                    height:'auto',
                    minHeight:'100vh',
                    marginLeft: '50px',
                  }}
                >
                  <Box
                    component="img"
                    src={one.default}
                    alt="Logo Top Left"
                    sx={{  width: '90px', height: '180px',position:'relative',left:'13px'}}
                  />
                <Box
                  component="img"
                  src={bottomBannerImg.default}
                  alt="Logo"
                  sx={{
                    position: "absolute",
                    bottom: 19,
                    right: 0,
                    width: '100%',
                    height: 'auto',
                    maxWidth: '600px',
                  }}
                />
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      marginTop: '100px',
                      fontFamily: 'Josefin Sans',
                      fontWeight: '900',
                      fontSize: '32px',
                      color: '#00A7D1'
                    }}
                  >
                    Introduction
                  </Typography>
                 
                  <Typography sx={{
                    fontFamily: 'Chromatica-Regular',
                    fontSize: '16px',
                    color: '#252525',
                    marginBottom:'20px'
                  }}>If we have an idea of your substance use patterns, we can better meet your needs. We're here to meet you where you're at and help you get to where you want to go. So, this is a judgment-free zone where you can be real.</Typography>
                  <Typography sx={{
                    fontFamily: 'Chromatica-Bold',
                    paddingTop: '10px',
                    fontSize: '20px',
                    color: '#252525'
                }}>Choose all substances relevant to you:</Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {scrollOffset > 0 && (
                    <img
                      onClick={this.handleScrollLeft}
                      style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' }}
                      src={backwardbutton.default}
                      alt="Previous"
                    />
                  )}


                  <div style={{ overflow: 'hidden', marginTop: '10px', marginBottom: '10px' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row', }}>
                      {visibleItems?.map((item: any) => (
                        <Grid
                          key={item.id}
                          style={{
                            width: '227px',
                            height: '204px',
                            position: 'relative',
                            marginRight: '10px',
                            marginLeft: '10px',
                            border: checkedItems[item.id] ? '2px solid transparent' : '2px solid #E3E3E3',
                            borderRadius: '10px',
                            background: checkedItems[item.id]
                              ? 'linear-gradient(#EFF8FB, #EFF8FB) padding-box, linear-gradient(#EBFF00, #00A7D1) border-box'
                              : 'white',
                          }}
                        >
                          <Checkbox
                            checked={checkedItems[item.id] || false}
                            onChange={() => this.handleCheckboxChange(item.id)}
                            sx={{
                              position: 'absolute',
                              borderRadius: '6px',
                              top: 0,
                              right: 0,
                              color: checkedItems[item.id] ? '#00A7D1' : '#E3E3E3',
                              '&.Mui-checked': {
                                color: '#00A7D1',
                              },
                            }}
                          />
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}>
                            <img src={imagealcohal.default} alt="Description" style={{ width: '80px', height: '80px' }} />
                          </div>
                          <div style={{
                            position: 'absolute',
                            bottom: '25px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}>{item.label}</div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  {scrollOffset + 5 < gridItems.length && (
                    <img
                      onClick={this.handleScrollRight}
                      style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' }}
                      src={rightmovearrow.default}
                      alt="Next"
                    />
                  )}
                </div>
                <Typography sx={{
                    fontFamily: 'Chromatica-Regular',
                    paddingTop: '10px',
                    fontSize: '16px',
                    color: '#252525'
                  }}> Next, you can think through what you want to see happen for yourself. Click on <span style={{ fontFamily: 'Chromatica-Bold' }}>“set goals”</span> below.
                  </Typography>
                  <Button
                  onClick={()=>{this.setGoals()}}
                    variant="contained"
                    sx={{
                      padding: "10px",
                      borderRadius: "16px",
                      boxShadow: 'none',
                      background:
                        "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                      textTransform: "none",
                      backgroundColor: '#EFF8FB',
                      fontSize: "18px",
                      lineHeight: "20px",
                      marginTop: '30px',
                      marginBottom: '30px',
                      cursor: 'pointer',
                      width: '348px',
                      height: '56px',
                      fontWeight: 400,
                      fontFamily: 'Chromatica-Bold',
                    }}
                    data-test-id="wellspring"
                  >
                    <span style={{ marginRight: '5px' }}>Set Goals</span>
                    <img src={arrowright.default} />
                  </Button>
                <Box
                  sx={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    px: 4,
                  }}
                >
                  
                </Box>
                </Box>
                </Grid>          
          </Grid>
        )
    }
}

const webStyle = {
  backbutton:{
   
    left: 82,
    cursor: "pointer",
    color:'#252525',
    fontFamily: 'Chromatica-Bold',
  },
  gridItem:{
    height: 'auto',
    width:'98.5%',
    margin: '10px',
    marginTop:'10px',
    marginBottom:'10px',
    borderRadius: '15px',
    backgroundColor: 'white',
   
  },
  name:{
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "20px",
    color: "#FFFFFF",
    mt: "3px",
  },
  span:{
    fontSize: "40px",
                  marginLeft: "40px",
                  fontFamily: "Josefin Sans",
                  fontWeight: 700,
                  marginBottom: "4px",
                  color: "#EBFF00",
                  lineHeight: "40px",
  },
  arrow:{
    fontSize: "40px",
    color: "#ffffff",
    fontFamily: "Josefin Sans",
    fontWeight: 300,
  },
  boxheader:{
    gap: "20px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    height: "100%",
    alignContent:'center'
  },
}


import React from "react";
// Customizable Area Start
import { Box,Grid ,Tabs,Tab,IconButton,CardContent,Card} from "@mui/material";
import { styled } from "@mui/material/styles"; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {  arrow_Arrow_arrow_down, arrow_Arrow_up, award_a, award_a1, award_a2, awrad_Award, flower_flower, hook_Hook, Menu_M, plus_Plus } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../../blocks/dashboard/assets/style.css"
const WhiteBackgroundContainer = styled('div')({
  backgroundColor: 'white', 
  padding: "2rem", 
   height:"auto",
  marginLeft:"1rem",
  marginRight:"1rem",
  borderRadius:"16px" 
});


 
// Customizable Area End
import GoalsInformationController, {
  Props,
  
} from "./GoalsInformationController.web";



export default class GoalsInformation extends GoalsInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


    icons = [
        <img src={award_a.default} alt="awrad_award" />,
        <img src={award_a1.default} alt="awrad_award" />,
        <img src={award_a2.default} alt="awrad_award" />,
    ];
    cardShowdata = () => {
        return (
            <>
                <Grid container spacing={2}>

                    {this.state.cardsData.map((card: any, index: any) => (
                        <Grid
                            item

                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={index}
                        >
                            <Card variant="outlined" style={{ position: 'relative', borderRadius: '8px', maxWidth: '100%', width: '310px' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem" }}>
                                            <Box style={{ width: '40px', paddingTop: "7px", borderRadius: '8px', border: "1px solid #E3E3E3" }}>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>  {card.icon} </span>
                                            </Box>
                                            <Box>
                                                <span style={webStyles.drinksStyle}>{card.title}</span>
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                        fontFamily:'Chromatica-Regular',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        color: '#252525',
                                        marginTop: "10px"
                                    }}>
                                        {card.description}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                        marginTop: 8,
                                        color: "#00A7D1",
                                        fontFamily: 'Chromatica-Bold',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '20px',


                                    }}>
                                        {card.dateRange}
                                    </Typography>
                                </CardContent>
                                <IconButton style={{ position: 'absolute', right: 8, top: 16 }}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </>
        )
    }
    tabSeprate = () => {
        return (
            <>
                <Tab
                    icon={<img src={hook_Hook.default} style={{ width: "35px", height: '35px', marginTop: "-6px" }} />}
                    label="Tobacco or Nicotine"
                    iconPosition="start"
                    sx={{
                        display: 'flex',
                        color: "$252525",
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: "stretch",
                        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
                        textTransform: "none",
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        borderTop: '1px solid #E5E5E5',
                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                        backgroundColor: this.state.selectValues === 2 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 2 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 2 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
                <Tab
                    icon={<img src={arrow_Arrow_up.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                    label="Uppers"
                    iconPosition="start"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: "$252525",
                        alignItems: "stretch",
                        justifyContent: 'flex-start',
                        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
                        textTransform: "none",
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        borderTop: '1px solid #E5E5E5',
                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                        backgroundColor: this.state.selectValues === 3 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 3 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 3 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
                <Tab
                    icon={<img src={arrow_Arrow_arrow_down.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                    label="Downers"
                    iconPosition="start"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: "$252525",
                        alignItems: "stretch",
                        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
                        textTransform: "none",
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        justifyContent: 'flex-start',
                        borderTop: '1px solid #E5E5E5',
                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                        backgroundColor: this.state.selectValues === 4 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 4 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 4 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
            </>
        )
    }
    subTabsinalcohoandall = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{ borderColor: 'divider', backgroundColor: "#F7F7F7"}}>

                            <Tabs
                                value={this.state.selectValues}
                                onChange={this.handleChangethird}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="substance tabs"

                                TabIndicatorProps={{
                                    style: { display: 'none' } // Hide default indicator
                                }}
                            >
                                <Tab
                                    icon={<img src={awrad_Award.default} style={{ width: "16px", height: '28px' }} />}
                                    label="Alcohol"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                      alignItems:"stretch",
                                        flexDirection: 'row',                                   
                                        fontFamily:'Chromatica-Bold' ,
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '28px',
                                        color: "$252525",
                                        justifyContent: 'flex-start',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5',
                                        borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectValues === 0 ? 'white' : 'transparent', // Background red when selected
                                        borderTopColor: this.state.selectValues === 0 ? '#E5E5E5' : 'transparent', // Top border color when selected
                                        borderRightColor: this.state.selectValues === 0 ? '#E5E5E5' : 'transparent', // Right border color when selected
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        // Adjust height
                                        padding: '8px 12px', // Adjust padding
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }// Smooth transition

                                    }}
                                />
                                <Tab
                                    icon={<img src={flower_flower.default} style={{ width: "37px", height: '37px',marginTop:"-6px"  }} />}
                                    label="Cannabis"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                        color: "$252525",
                                        flexDirection: 'row',
                                        alignItems:"stretch",
                                        justifyContent: 'flex-start',
                                        fontFamily:'Chromatica-Bold' ,
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px', borderBottom:"1px solid #E5E5E5",
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5',
                                        borderRadius: "1px 8px 0px 8px",
                                        backgroundColor: this.state.selectValues === 1 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectValues === 1 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectValues === 1 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                            borderBottom:"none",
                                        }
                                    }}
                                />
                              {this.tabSeprate()}
                                <Tab
                                    icon={<img src={plus_Plus.default} style={{ width: "34px", height: '34px',marginTop:"-6px"   }} />}
                                    label="Others"
                                    iconPosition="start"

                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
                                        textTransform: "none",
                                        color: "$252525",
                                        alignItems:"stretch",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        justifyContent: 'flex-start',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectValues === 5 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectValues === 5 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectValues === 5 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                            </Tabs>
                       

                        </Box>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.selectValues === 0 && <><this.cardShowdata/></>}
                            {this.state.selectValues === 1 && <><div>ddddddd</div></>}
                        </Box>
                    </Grid>
                </Grid>
  
        </>
    )
   }
HeaderFunction = () =>{
    return (
        <>
         <Grid item style={webStyles.topParentGrid}>
        <Grid item style={webStyles.parentGrid}>
          <Box  sx={webStyles.childGrid}>
            <Box component="span" sx={webStyles.wellStyle}>
            <span>Well</span>
            </Box>
            <Box component="span" sx={webStyles.SpringStyle}>
             <span> Spring</span>
            </Box>
            <Box sx={webStyles.heyTomStyle} >
              Hey, Tom Chandler
            </Box>
            <Box sx={webStyles.parentBox}>
                  <img
                    onClick={() => { this.hamburgerMenunext() }}
                    src={Menu_M.default}
                    style={{ cursor: "pointer" }}
                    alt="Hamburger Menu"
                  />
                </Box>
          </Box>
        </Grid>
    </Grid>
        </>
    )
}
    tabThisWeekfunction = () =>{
    return (
        <>
            <Box>
                <Box>
                    <Typography sx={webStyles.Statisticsstyle}>
                        Statistics
                    </Typography>
                </Box>
                <Box style={{ marginTop: "1rem" }}>
                    <Typography sx={webStyles.youaidyourStyle}>This week, you said your Goals are to:</Typography>
                </Box>
                <Box>
                    <Box sx={{ width: '100%',marginTop:'1.5rem' }}>
                        <Tabs
                        data-test-id="handleTab"
                            value={this.state.values}
                            onChange={this.handleChangesecond}
                            aria-label="basic tabs example"

                            TabIndicatorProps={{
                                style: {
                                    position: 'absolute',
                                    height: '2px',
                                    bottom: '0px',
                                    width: '100%',
                                    border:"none",
                                     backgroundColor: 'white', 
                                },
                            }}

                           
                        >
                             <Tab
                                label="Substance Related Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#A0A0A0", 
                                    textTransform: "none",
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 0 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                    }
                                }}
                            />
                           
                          
                        
                          <Tab
                                label="Other Life Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400, borderBottom:"1px solid #E5E5E5",
                                    lineHeight: "20px",
                                    textTransform: "none",
                                    color: "#A0A0A0", 
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 1 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px", // Style for the selected tab
                                    }
                                }}
                            />
                         

                        </Tabs>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.values === 0 && <this.subTabsinalcohoandall/>}
                        </Box>
                    </Box>
                </Box>

     
                
        </Box>
        </>
    )
   }
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    
   
    return (
      //Merge Engine DefaultContainer
  <>
  {this.HeaderFunction()}
        <WhiteBackgroundContainer>
                <Box sx={{display:"flex",justifyContent:'flex-start',alignItems:"center",gap:'0.5rem', padding: "1rem 0", }}
            onClick={() => { this.goToBackDashboard() }}
                >
                    <KeyboardBackspaceIcon />
                     <Typography style={webStyles.backStyle}>Back</Typography>
                </Box>
               <Box style={{marginTop:"2rem"}}>
               <Box style={webStyles.addGoalsStyle}>
                    <Box>
                         <Typography sx={webStyles.goalsStyleleft}>Goals</Typography>
                    </Box>
                    <Box>
                        <button
                        style={{
                            padding: "0.5rem",     
                            width: "194px",          
                            borderRadius: "8px",     
                            border: "none",           
                            background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                        }}
                        >
                           <div style={webStyles.iconflex}>
                           <Typography sx={webStyles.addGoalsStyleAdd}>Add More Goals</Typography>
                           <AddCircleOutlineIcon style={{width:"16px",height:"16px",color:"#DADADA"}}/>
                           </div>
                        </button>
                     </Box>
                </Box>
               </Box>

                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            aria-label="basic tabs example"
                            sx={{ borderBottom: 2, borderColor: 'divider',color:"#E3E3E3" }}
                            TabIndicatorProps={{
                                style: {
                                     backgroundColor: '#00A7D1', // Custom indicator color
                                },
                            }}
                        >
                            <Tab
                                label="This week"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#A0A0A0", // Text color
                                    textTransform: "none",
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                        // Text color // Style for the selected tab
                                    }
                                }}
                            />
                            <Tab
                                label="Last week"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,  // Numeric value for font-weight
                                    lineHeight: "20px",

                                    textTransform: "none",
                                    color: "#A0A0A0", // Text color
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px", // Style for the selected tab
                                    }
                                }}
                            />

                        </Tabs>
                        <Box sx={{ p: 3 }}>
                            {this.state.value === 0 && <this.tabThisWeekfunction/>}
                            {this.state.value === 1 && ""}
                           
                        </Box>
                    </Box>
                </Box>

              

          
          
        </WhiteBackgroundContainer>
     
        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    drinksStyle:{
        fontFamily:'Chromatica-Regular' ,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
    },
    topParentGrid:{
        padding: '10px',
        backgroundColor: 'lightgray',
    },
    parentBox:{
        right: 0,
        marginRight: '30px',
        position: "absolute",
        top: "12px",
    },
    heyTomStyle:{
        position: "absolute",
        top: "20px",
        left: "260px",
        fontSize: "17px",
        width: '180px',
        color: "white",
        fontFamily: 'Chromatica-Bold',
    },
    SpringStyle:{
        fontSize: "40px", fontWeight: "normal", marginLeft: "5px" 
    },
    wellStyle:{
        fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px"
    },
    childGrid:{
        color: "#ffffff",
        fontFamily: 'Josefin Sans',
        position: "absolute",
        top: "36px",
        left: '48px',
        width:'95%'
    },

    parentGrid:{
        height: '88px',
        backgroundColor: '#0F4257',
        margin: '10px',
        borderRadius: '16px',
        width: '99%',
    },
    youaidyourStyle:{
        fontFamily:'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color:"#252525"
    },
    Statisticsstyle:{
        fontFamily:'Chromatica-Regular' ,
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        color:"#000000"
    },
    iconflex:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:'0.5rem'
    },
    buttonStyleadd:{
        width: "194px",          // Fixed width of 194px
    padding:"0.5rem",              // Gap between children elements (works in flexbox or grid)
        borderRadius: "8px 0 0 0",  // Border-radius (top-left rounded)
        opacity: 0
    },
    goalsStyleleft:{
        fontFamily: "Josefin Sans",
        fontSize: "32px",
        fontWeight: 600, // Numeric value for font-weight
        lineHeight: "32px",
        color:"#00A7D1"
    },
    addGoalsStyleAdd:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "24px",
        color:'#ffffff',
       
    },
    addGoalsStyle:{
        display:"flex",justifyContent:'space-between',alignItems:"center"
    },
    backStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "20px"
    },
    flexproperty: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
  },
    testStyleforHeyTom: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#ffffff",
        marginLeft: "1.3rem"
    },
    HeyTomStyle: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#EBFF00"
    },
  threebythreeStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    
  },
  labelstylestepper:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStyle:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1"

}
};

// Customizable Area End
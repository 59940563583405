import React from 'react';
import {
    Grid,
    Box,
    Typography,
    Radio, FormControlLabel, 
    FormControl,
    Button,
    Checkbox,
    TextField,Backdrop, CardActions, Card, CardContent
} from "@mui/material";
import GoalsController, {
    Props,
} from './GoalsController.web';
import { arrow, edit, quotes, three, substanceBottom, arrowright, time, calendar,  menuIcon } from './assets';
import moment from 'moment';



export const configJSON = require("./config.js");


export default class SubstanceGoals extends GoalsController {
    constructor(props: Props) {
        super(props);

    }
    render() {
        const { checkedItems } = this.state;
        const timeSets = this.state.timeSet ? moment(this.state.timeSet.$d).format('hh:mm A') : '';

        return (
            <Grid
                item
                style={{
                    overflowY:'scroll',
                    height:'100%',
                    backgroundColor: "#F0F0F0",
                }}
            >
                <Grid
                    item
                    style={{
                        height: '88px',
                        backgroundColor: '#0F4257',
                        borderRadius: '16px',
                        width: '98.5%',
                        margin: '10px',
                    }}
                >
          <Box
            sx={{
            gap: "20px",
              alignItems: "center",     
              width: "50%",
              height: "100%",
              display: "flex",
              alignContent:'center'

            }}
          >
            <Box
              sx={{
                
                color: "#ffffff",
                fontFamily: "Josefin Sans",
                fontWeight: 300,
                fontSize: "40px",
              }}
            >
              <span
                style={{
                 
                  fontFamily: "Josefin Sans",
                  fontWeight: 700,
                  fontSize: "40px",
                  marginLeft: "40px",
                  marginBottom: "4px",
                  color: "#EBFF00",
                  lineHeight: "40px",
                }}
              >
                Well
              </span>
              Spring
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
              }}
            >
              Hey, Tom Chandler
            </Typography>
            <Box
                 onClick={()=>{this.hamburgerMenu()}}
                  component="img"
                  sx={{       
                    right: "42px",
                    position: "absolute",
                    marginRight: "40px",
                  }}
                  src={menuIcon.default}
                  alt="menuIcon"
                 
                />
         
          </Box>
                </Grid>

                <Grid
                    item
                    style={{
                        marginTop: '10px',
                        margin: '10px',
                        height:'auto',
                        minHeight:'100vh',
                        width: '98.5%',
                        marginBottom: '10px',
                        borderRadius: '15px',
                        backgroundColor: 'white',
                        position: 'relative',
                       
                    }}
                >
                    <Box onClick={() => this.goalsPage()} sx={{  cursor: 'pointer',paddingTop: '30px' }} data-test-id="back-btn-goals">
                        <Box
                            alt="Back Button"
                            sx={{  paddingTop: '6px',left: 60, position: "absolute", }}
                            component="img"
                            src={arrow.default}
                        />
                        <Typography
                            sx={{
                                cursor: "pointer",
                                fontFamily: 'Chromatica-Bold',
                                left: 82,
                                color:'#252525',
                                position: "absolute",
                            }}>
                            Back</Typography>
                    </Box>

                    <Box sx={{ top: 19, marginLeft: '50px' ,position: "relative", }}>
                        <Box
                            alt="Img"
                            component="img"
                            src={three.default}
                            sx={{ width: '120px', height: '180px' }}
                        />
                        <Typography
                            sx={{
                                marginTop: '112px',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                fontFamily: 'Josefin Sans',
                                fontWeight: '900',
                                fontSize: '32px',
                                color: '#00A7D1'
                            }}
                        >
                            Substance Related Goals
                        </Typography>
                        <Typography sx={{
                            color: '#252525',
                             fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            marginBottom: '20px'
                        }}>
                            This is a place for you to make your substance use goals even more concrete by setting them for a certain day.
                        </Typography>
                        <Typography sx={{
                            paddingBottom: '10px',
                            fontFamily: 'Chromatica-Bold',
                            fontSize: '20px',
                            color: '#252525'
                        }}>You said you wanted to..</Typography>
                        <div style={{ position: 'relative', width: '691px', marginBottom: '15px' }}>
                            <TextField
                                defaultValue="Reduce your typical drinking amount from 6 to 3 drinks a day!"
                                style={{
                                    width: '100%',
                                    height: '107px',
                                    background: `url(${quotes.default})`,
                                    backgroundSize: 'auto',
                                    backgroundPosition: 'top left',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: '16px',
                                }}
                                inputProps={{
                                    style: {
                                        fontSize: '16px',
                                        fontFamily: 'Chromatica-Regular',
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '16px',
                                        height: '100%',
                                        '&:hover fieldset': {
                                            borderColor: '#E3E3E3',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E3E3E3',
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            />
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <img src={edit.default} alt="Edit Goal" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                <span style={{
                                    color: '#00A7D1',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',

                                }}>
                                    Edit Goal
                                </span>
                            </div>
                        </div>
                        <div>
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}>
                                When do you want to make it happen?
                            </Typography>
                            <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openCalender)}>
                                {this.state.openCalender && (
                                    <Card style={{
                                        width: '400px', height: '480px', display: 'flex', flexDirection: 'column',
                                        borderRadius: '16px', padding: '25px 10px 15px 10px',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>
                                        <CardContent style={{
                                            display: 'flex', flexDirection: 'column',
                                            gap: '35px', alignItems: 'center', height: "400px"
                                        }}>
                                            <Typography style={{
                                                color: '#21355F', height: '24px', width: '512px',
                                                fontFamily: 'Chromatica-Regular', fontSize: '20px', textAlign: 'center'
                                            }}>
                                                Select other Date
                                            </Typography>
                                            
                                        </CardContent>
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                width: '90px', height: '43px',
                                                border: '1px solid #E1E1E1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px', background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',
                                            }}
                                                data-test-id="closebtn"
                                                onClick={() => this.closePopUp()}
                                            > Back
                                            </Button>
                                            <Button style={{
                                                width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px', background: '#0075BF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmCal()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )}
                            </Backdrop>

                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    flexWrap: 'wrap',
                                    gap: '10px'
                                }}>
                                    {this.state.options?.map((option: any) => (
                                        <Box
                                            key={option.value}
                                            sx={{
                                                display: 'inline-flex',
                                                border: this.state.selectedValue === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                                backgroundColor: this.state.selectedValue === option.value ? '#EFF8FB' : 'transparent',
                                                borderRadius: '32px',
                                                padding: '8px 12px',
                                                alignItems: 'center',
                                                transition: 'background-color 0.3s, border-color 0.3s',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={this.state.selectedValue === option.value}
                                                        onChange={this.handleChange}
                                                        value={option.value}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#00A7D1',
                                                            },
                                                            color: '#E3E3E3'
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                                }
                                            />
                                        </Box>
                                    ))}
                                </div>
                            </FormControl>

                            {this.state.selectedValue === 'other' && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                        type="text"
                                        placeholder="Choose Day"
                                        value={this.state.selectedDate || ''}
                                        style={{
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            outline: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                       
                                        readOnly
                                    />
                                    <img
                                        src={calendar.default}
                                        alt="Open Calendar"
                                        style={{ cursor: 'pointer', width: '44px', height: '44px' }}
                                        
                                    />
                                </div>
                            )}
                        </div>

                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginBottom: '10px',
                            marginTop: '30px'
                        }}
                        > At what time you need to be reminded?</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                <input
                                    
                                    type="text"
                                    placeholder="Select Time"
                                    value={timeSets}
                                    style={{
                                        width: '100%',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        border: 'none',
                                        outline: 'none',
                                        marginRight: '10px',
                                        backgroundColor: 'transparent',
                                    }}
                                    readOnly
                                />
                                <img
                                    src={time.default}
                                    alt="Open Calendar"
                                    style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                  
                                />
                            </div>
                            {timeSets && (
                                <span style={{ color: '#189718', marginLeft: '10px', fontFamily: 'Chromatica-Regular',fontSize:'16px' }}>
                                    {`Great! We'll remind you at ${timeSets}`}
                                </span>
                            )}
                        </div>

                        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupTime(this.state.openTime)}>
                            {this.state.openTime && (
                                <Card style={{
                                    width: '328px', height: '450px', display: 'flex', flexDirection: 'column',
                                    borderRadius: '28px', padding: '25px 10px 15px 10px',
                                    gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                }}>
                                    <CardContent style={{
                                        display: 'flex', flexDirection: 'column',
                                        gap: '35px', alignItems: 'center', height: "415px"
                                    }}>

                                    </CardContent>
                                </Card>
                            )}
                        </Backdrop>
                        <div style={{ display: 'flex' }}>
                            <Checkbox
                                checked={checkedItems[1] || false}
                                onChange={() => this.handleCheckboxChange(1)}
                                sx={{
                                    marginLeft: '-15px',
                                    borderRadius: '6px',
                                    color: checkedItems[1] ? '#00A7D1' : '#E3E3E3',
                                    '&.Mui-checked': {
                                        color: '#00A7D1',
                                    },
                                }}
                            />
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '10px'
                            }}
                            >  Do you want to make it happen on a daily basis?</Typography>
                        </div>

                        <Button
                           onClick={()=>{this.customForm()}}
                            variant="contained"
                            sx={{
                                padding: "10px",
                                borderRadius: "16px",
                                boxShadow: 'none',
                                background:
                                    "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                                textTransform: "none",
                                backgroundColor: '#EFF8FB',
                                fontSize: "18px",
                                lineHeight: "20px",
                                marginTop: '30px',
                                marginBottom: '30px',
                                cursor: 'pointer',
                                width: '348px',
                                height: '56px',
                                fontWeight: 400,
                                fontFamily: 'Chromatica-Bold',
                            }}
                            data-test-id="wellspring"
                        >
                            <span style={{ marginRight: '5px' }}>Next</span>
                            <img src={arrowright.default} />
                        </Button>
                        <Box
                            sx={{
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 4,
                            }}
                        >
                            
                        </Box>
                    </Box>
                    <Box
                                alt="image" component="img"   src={substanceBottom.default}
                                sx={{  
                                    bottom: 0,
                                    right: 0,
                                    width: '100%',
                                    position: "absolute",
                                    height: 'auto',
                                    maxWidth: '600px',
                                }}
                              
                            />
                </Grid>
            </Grid>
        )
    }
}


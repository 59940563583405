import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


export const baseURL = require("../../../framework/src/config.js");




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectsafeValues:number;
  tabValue:number;
  cardsDataSafe:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaferuseStrategiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  deleteBulkUploadCallId: string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  
 
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   
      selectsafeValues:0,
      tabValue:0,
      cardsDataSafe: [
        {
          title: 'Increase Water Intake',
          description: 'Increase from 4 to 8 glasses per day',
          dateRange: '5/1 - 5/15',
          icon: '',
        },
        {
          title: 'Get More Sleep',
          description: 'Increase from 6 to 8 hours per night',
          dateRange: '5/2 - 5/16',
          icon: '',
        },
        {
          title: 'Daily Exercise',
          description: 'Do 30 minutes of exercise daily',
          dateRange: '5/3 - 5/17',
          icon: '',
        },
        {
          title: 'Healthy Eating',
          description: 'Eat 5 servings of fruits and vegetables daily',
          dateRange: '5/4 - 5/18',
          icon: '復',
        },
        {
          title: 'Meditation',
          description: 'Practice 10 minutes of meditation daily',
          dateRange: '5/5 - 5/19',
          icon: '律',
        },
        {
          title: 'Reduce Screen Time',
          description: 'Limit screen time to 2 hours per day',
          dateRange: '5/6 - 5/20',
          icon: '',
        },
        {
          title: 'Stay Hydrated',
          description: 'Drink at least 2 liters of water per day',
          dateRange: '5/7 - 5/21',
          icon: '磻',
        },
        {
          title: 'Walk Daily',
          description: 'Take a 20-minute walk every day',
          dateRange: '5/8 - 5/22',
          icon: '‍♀️',
        }
      ],
    
     
   
     
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
  

    // Customizable Area End
  }

  // Customizable Area Start
  handleStretigies = (event:any ,newValues: any) => {
    this.setState({ selectsafeValues: newValues });
  }
  goToBackDashboards = () =>{
    this.props.navigation.navigate("DashboardInformation")
  }
  goToSaferseStrategies = () => {
    this.props.navigation.navigate("SaferuseStrategies")
  }
  hamburgerMenusafestr(){
    this.props.navigation.navigate("HamburgerMenu")
  }
  handleChangeSte = ( event:any,newValue: any) => {
    this.setState({ tabValue: newValue });
  };
  // Customizable Area End
}
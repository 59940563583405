import React from "react";
// Customizable Area Start
import { Box,Grid ,Paper} from "@mui/material";
import { styled } from "@mui/material/styles"; 
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepConnector from "@mui/material/StepConnector";
import {  award_3, award_a4, award_a5, Menu_M, ThumbBoy_b } from "./assets";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "../../../blocks/dashboard/assets/style.css"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';



const data = [
  { month: 'Jan', harm: 37 },
  { month: 'Feb', harm: 35 },
  { month: 'Mar', harm: 32 },
  { month: 'Apr', harm: 31 },
  { month: 'May', harm: 30 },
  { month: 'Jun', harm: 28 },
  { month: 'Jul', harm: 24 },
  { month: 'Aug', harm: 22 },
  { month: 'Sep', harm: 20 },
  { month: 'Oct', harm: 19 },
  { month: 'Nov', harm: 18 },
  { month: 'Dec', harm: 22 },
];
const StepIconste = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage: "linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  left: 'calc(-85% + 2px)',
  right: 'calc(15% + 2px)',
  display: 'block', // Display block as per your CSS
  borderColor: '#B7D340', // New border color
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  borderRadius:"36px",

  alternativeLabel: {
    top: 22,
    left: "-170px",
    right: "19px",
   
    
  },
  active: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
}));


const ColorlibStepIcon = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
  backgroundColor: "#F3A622",
  zIndex: 1,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage: "linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const WhiteBackgroundContainer = styled('div')({
  background: 'white', 
  padding: '2rem', 
 height:"auto",
  marginLeft:"1rem",
  marginRight:"1rem",
  borderRadius:"16px" 
});



 
// Customizable Area End
import DashboardControllerInformation, {
  Props,
  configJSON
} from "./DashboardControllerInformation.web";



export default class DashboardInformation extends DashboardControllerInformation {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


   icons = [
     <img src={award_3.default} alt="awrad_award"/>,
     <img src={award_a4.default} alt="awrad_award"/>,
     <img src={award_a5.default} alt="awrad_award"/>,
   ];
   headerMiddle = ()=>{
    return (
      <>
        <Grid
          item
          style={{
            height: '88px',
            backgroundColor: '#0F4257',
            borderRadius: '16px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              color: "#ffffff",
              fontFamily: 'Josefin Sans',
              position: "absolute",
              top: "36px",
              left: '48px',
              width:'95%'
            }}
          >
            <Box component="span" sx={{ fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px" }}>
            {configJSON.Well}
            </Box>
            <Box component="span" sx={{ fontSize: "40px", fontWeight: "normal", marginLeft: "5px" }}>
            {configJSON.Spring}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                left: "260px",
                fontSize: "17px",
                width: '180px',
                color: "white",
                fontFamily: 'Chromatica-Bold',
              }}
            >
             {configJSON.HeyTomChandler}
            </Box>
            <Box
                  sx={{ 
                    right: 0,
                    marginRight: '30px',
                    position: "absolute",
                    top: "12px",
                  }}
                >
                  <img
                    onClick={() => { this.hamburgerMenu() }}
                    src={Menu_M.default}
                    style={{ cursor: "pointer" }}
                    alt="Hamburger Menu"
                  />
                </Box>
          </Box>
        </Grid>
      </>
    )
   }
 nextStepperFunc = () =>{
  return (
    <>
      <Grid item md={6} lg={6} sm={12}>
               <Paper style={{ width: "100%", height: "300px", background: "#DFEDF0", borderRadius: "16px" }}>
                <Box style={{ padding: "2rem" }}>
                  <Typography sx={webStyles.goalStyles}>Safer-Use Strategies</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeSteps}
                  connector={<ColorlibConnector />}
                  sx={webStyles.stepperStyle}
                >
                  {this.steps.map((label: any, index: any) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <StepIconste {...props} completed={this.state.activeSteps > index}>
                            {this.icons[index]} 
                          </StepIconste>
                        )}
                      >
                        <span style={webStyles.labelstylesteppers}>{label}</span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>

                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ padding: '1.3rem', marginTop: '0.2rem' }}>
                        <Box style={{ width: "195px", background: "#CFE472", padding: "0.4rem", borderRadius: "40px", cursor: "pointer" }}>
                          <Button
                            data-test-id="handlemoreawrad"
                            onClick={this.handleNexts}
                          >
                            <Typography sx={webStyles.threebythreeStyles}>1/3 Task Completed!</Typography>
                          </Button>
                        </Box>
                      </div>
                      <div>
                        <Typography sx={webStyles.viewStyle}>{configJSON.viewMore}</Typography>

                      </div>
                    </div>

                    <div>
                    
                    </div>
                  </div>

                </div>
              </Paper>
           
            </Grid>
    </>
  )
 }
    CustomTooltip = (props:any) => {
    const { active, payload, label } = props; 
  
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
         
          padding: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          ...webStyles.tooltipStyle, 
        }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
          <p style={{ margin: 0 }}>{`Harm: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    
   
    return (
      //Merge Engine DefaultContainer
  <>
   <Grid
        item
        style={{
          padding: '10px',
          background: 'lightgrey',
        }}
      >
      {this.headerMiddle()}
        </Grid>
        <WhiteBackgroundContainer>
          <Box style={{ marginBottom: "2rem" }}>
            <Typography style={webStyles.YourStyles}>Your Dashboard</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={6} lg={6} sm={12}>
              <Paper style={{ width: "100%", height: "300px", background: "#F1F7D0", borderRadius: "16px" }}>
                <Box style={{ padding: "2rem" }}  onClick={() => { this.goTodashnextScreen() }}>
                  <Typography sx={webStyles.goalStyles}>Goals</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeStep}
                  connector={<ColorlibConnector />}
                  sx={webStyles.stepperStyle}
                >
                  {this.steps.map((label: any, index: any) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <ColorlibStepIcon {...props} completed={this.state.activeStep > index}>
                            {this.icons[index]} 
                          </ColorlibStepIcon>
                        )}
                      >
                        <span style={webStyles.labelstylesteppers}>{label}</span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>

                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",gap:"0"}}>
                      <div style={{ padding: '1.3rem', marginTop: '0.2rem' }}>
                        <Box style={{ width: "195px", background: "#CFE472", padding: "0.4rem", borderRadius: "40px", cursor: "pointer" }}>
                          <Button
                            data-test-id="handlemoreawrad"
                            onClick={this.handleNext}
                          >
                            <Typography sx={webStyles.threebythreeStyles}>3/3 Task Completed!</Typography>
                          </Button>
                        </Box>
                      </div>
                      <div>
                        <Typography sx={webStyles.viewStyle}>{configJSON.viewMore}</Typography>

                      </div>
                    </div>
                    
                  </div>

                </div>
              </Paper>
            </Grid>
          {this.nextStepperFunc()}
          </Grid>
          <Grid container style={{ marginTop: "2rem" }} spacing={2}>
            <Grid item md={12} lg={12}>
              <Paper style={{ background: "#5C61DB", borderRadius: "16px", height: "auto" }}>
                <Box sx={webStyles.flexpropertys}>

                  <Box sx={{ marginLeft: "1rem", position: "relative" }}>
                    <Box sx={{ padding: "1rem" }}>
                      <Typography sx={webStyles.HeyTomStyles}>Hey, Tom!</Typography>
                    </Box>
                    <Typography style={webStyles.testStyleforHeyToms}>
                      No one's path in life is perfect. Great job coming back to keep working on things! <br />
                      This is a huge step towards changing your substance-related harm.
                    </Typography>
                    <span style={{ color: "#5C61DB" }}>none</span>

                    {/* Image Box */}
                    <Box sx={{ position: "relative", zIndex: 1, textAlign: "right" }}>
                      <img
                        src={ThumbBoy_b.default}
                        alt="Thumbnail"
                        style={{
                          width: "239px",
                          height: "160px",
                          position: "absolute",
                          top: 0,
                          transform: "translate(100%, -100%)",
                          right: "-44%",
                        }} 
                      />
                    </Box>
                  </Box>
                </Box>

              </Paper>
            </Grid>

          </Grid>
          <Grid container style={{ marginTop: "2rem" }} spacing={2}>
            <Grid item md={12} lg={12}>
              <Paper style={{borderRadius: "16px", height: "auto" }}>
             <Box style={webStyles.leftAndrightbox}>
             <Box  sx={{ marginLeft: "1rem", marginTop:"0.6rem" }}>
                <Typography sx={webStyles.relatedHarmstyle}>Related Harm Reduction</Typography>
              </Box>
              <Box style={{paddingRight:"2rem", marginLeft: "1rem", marginTop:"0.6rem" }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                    data-test-id="monthHandle"
                      value={this.state.month}
                      onChange={this.handleChangemonth}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ width: '187px', height: '40px',borderRadius:"16px",border: "1px solid #E3E3E3" }}
                    >
                      <MenuItem value="">
                        month
                      </MenuItem>
                      <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                </Box>
                <ResponsiveContainer width="100%" height={400} style={{ marginTop: "1rem" }}>
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" style={webStyles.XandYbothStyle} />
                    <YAxis  style={webStyles.XandYbothStyle}/>
                    <Tooltip content={<this.CustomTooltip />} />
                    <Legend />
                    <Line type="monotone" dataKey="harm" stroke="#8884d8" activeDot={{ r: 8 }} />
                    
                  </LineChart>
                </ResponsiveContainer>

              </Paper>
            </Grid>

          </Grid>

          
          
        </WhiteBackgroundContainer>
     
        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  viewStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    paddingRight: "2rem"
  },
  stepperStyle:{
    marginLeft: "-0.5rem", marginRight: "-0.5rem"
  },
  tooltipStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#00A7D1",
    borderRadius:"33px"
  },
  XandYbothStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"
  },
  leftAndrightbox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
   
  },
  relatedHarmstyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color:"#000000",
    padding:'1rem'
  },
  flexpropertys:{
display:"flex",
justifyContent:"space-between",
alignItems:"center"
  },
  testStyleforHeyToms:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color:"#ffffff",
    marginLeft:"1.3rem"
  },
  HeyTomStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"#EBFF00"
  },
  threebythreeStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    
  },
  labelstylesteppers:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyles:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStyles:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1"

}
};



// Customizable Area End
import React from 'react';
import {
    Grid,
    Box,
    Typography,
    Radio, FormControlLabel, 
    FormControl,
    Button,
    Checkbox,
    Backdrop, CardActions, Card, CardContent
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import OtherGoalsController, {
    Props,
} from './OtherGoalsController.web';
import { arrow,menuAdded,  three, bottomBannerImg,time, calendar, arrowright, backwardbutton, rightmovearrow } from './assets';




export const configJSON = require("./config.js");


export default class OtherGoals extends OtherGoalsController {
    constructor(props: Props) {
        super(props);

    }
    render() {
        const { scrollOffset,gridItems,checkedIte } = this.state;
        const visibleItems = this.state.gridItems.slice(scrollOffset, scrollOffset + 5);
        const { checkedItems } = this.state;
        const timeSets = this.handleTimeSet();

        return (
            <Grid
                item
                style={{
                    overflowY:'scroll',
                    backgroundColor: "#F0F0F0",                 
                    height:'100%',
                }}
            >
                <Grid
                    item
                    style={{
                        height: '88px',
                        backgroundColor: '#0F4257',
                        margin:'10px',
                        borderRadius: '16px',
                        width: '98.5%',
                    }}
                >
                      <Box sx={{
              gap: "20px", display: "flex",
              alignItems: "center",
              width: "50%",
              height: "100%",
              alignContent:'center'

            }}
          >
            <Box
              sx={{
                color: "#ffffff",
                fontSize: "40px", 
                fontWeight: 300,
                fontFamily: "Josefin Sans",
              }}>
              <span
                style={{
                    marginBottom: "4px",
                    color: "#EBFF00",
                  fontFamily: "Josefin Sans",
                  fontWeight: 700,             
                  lineHeight: "40px",
                  fontSize: "40px",
                  marginLeft: "40px",
                }}
              >
                Well
              </span>
              Spring</Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
                fontSize: "20px",
               
              }}
            >
              Hey, Tom Chandler
            </Typography>
            <Box
                  src={menuAdded.default}
                  onClick={()=>{this.hamburgerPage()}}
                  component="img"
                  sx={{       
                    right: "42px",
                    position: "absolute",
                    marginRight: "40px",
                  }}
                  alt="menuIcon"
                 
                />
          
          </Box>
                </Grid>

                <Grid
                    item
                    style={{
                        marginTop: '10px',
                        margin: '10px',
                        height:'auto',
                        minHeight:'100vh',
                        backgroundColor: 'white',
                        marginBottom: '10px',
                        position: 'relative',
                        width: '98.5%',            
                        borderRadius: '15px',
                    }}
                >
                    <Box onClick={() => this.goalsPage()} data-test-id="back-btn-goals" sx={{ paddingTop: '30px', cursor: 'pointer' }} >
                        <Box
                          sx={{ left: 60, position: "absolute", paddingTop: '6px' }}
                            component="img"
                            alt="Back"            
                            src={arrow.default}
                        />
                        <Typography
                            sx={{                  
                                fontFamily: 'Chromatica-Bold',
                                cursor: "pointer",
                                left: 82,
                                color:'#252525',
                                position: "absolute",
                            }}>Back</Typography></Box>

                    <Box 
                    sx={{ 
                    top: 19, 
                    position: "relative", 
                    marginLeft: '50px' }}>
                        <Box
                          src={three.default}
                            alt="Logo Top Left"
                            component="img"                
                            sx={{ width: '120px', height: '180px' }}
                        />
                        <Typography
                            sx={{    
                                fontSize: '32px',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                marginTop: '112px',
                                fontFamily: 'Josefin Sans',
                                fontWeight: '900',
                                color: '#00A7D1'
                            }}
                        >
                           Other Life Goals</Typography>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginBottom: '20px'
                        }}
                        >
                            We are here to meet you where you're at. So, thanks for showing up and choosing a step towards wellness today!
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Bold',
                            fontSize: '20px',
                            paddingBottom: '10px',
                            color: '#252525'
                        }}>What is one area you want to work on?</Typography>
                         <div style={{ display: 'flex', alignItems: 'center' }}>
                  {scrollOffset > 0 && (
                    <img
                      onClick={this.handleScrollLeft}
                      style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' }}
                      src={backwardbutton.default}
                      alt="Previous"
                      data-test-id="scrollleft"
                    />
                  )}


                  <div style={{ overflow: 'hidden', marginTop: '10px', marginBottom: '10px' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row', }}>
                      {visibleItems?.map((item: any) => (
                        <Grid
                          key={item.id}
                          style={{
                            width: '227px',
                            height: '204px',
                            position: 'relative',
                            marginRight: '10px',
                            marginLeft: '10px',
                            border: this.getItemBorder(item.id),
                            borderRadius: '10px',
                            background: this.getItemColor(item.id)
                          }}
                        >
                          <Checkbox
                           onChange={() => this.handleCheckboxChange(item.id)}
                            checked={checkedItems[item.id] || false}     
                            sx={{
                              right: 0,
                              color: checkedItems[item.id] ? '#00A7D1' : '#E3E3E3',
                              '&.Mui-checked': {
                                color: '#00A7D1',
                              },
                              position: 'absolute',
                              borderRadius: '6px',
                              top: 0,
                            }}
                          />
                          <div style={{
                            left: '50%',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}>
                            <img  alt="Description" src={three.default} style={{ width: '80px', height: '80px' }} />
                          </div>
                          <div style={{
                            position: 'absolute',
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            bottom: '25px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                          }}>{item.label}</div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  {scrollOffset + 5 < gridItems.length && (
                    <img
                      src={rightmovearrow.default}
                      data-test-id="scrollright"
                      onClick={this.handleScrollRight}
                      style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' }}
                      alt="Next"
                    />
                  )}
                </div>
                     <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}>
                                What is one thing you can do for your sleep this week?</Typography>
                                <input
                                        type="text"
                                        placeholder="Type here..."
                                       
                                        style={{
                                            width: '894px',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border:'none',
                                            outline: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                            borderBottom: '1px solid #E1E1E1',
                                        }}
                                        
                                       
                                    /> <div>
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}>
                                When do you want to make it happen?
                            </Typography>
                            <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openCalender)}>
                                {this.state.openCalender && (
                                    <Card style={{
                                        width: '400px', height: '480px', display: 'flex', flexDirection: 'column',
                                        borderRadius: '16px', padding: '25px 10px 15px 10px',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>
                                        <CardContent style={{
                                            display: 'flex', flexDirection: 'column',
                                            gap: '35px', alignItems: 'center', height: "400px"
                                        }}>
                                            <Typography style={{
                                                color: '#21355F', height: '24px', width: '512px',
                                                fontFamily: 'Chromatica-Regular', fontSize: '20px', textAlign: 'center'
                                            }}>
                                                Select other Date
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              
                                            </LocalizationProvider>
                                        </CardContent>
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                width: '90px', height: '43px',
                                                border: '1px solid #E1E1E1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px', background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',
                                            }}
                                                data-test-id="closebtn"
                                                onClick={() => this.closePopUp()}
                                            > Back
                                            </Button>
                                            <Button style={{
                                                width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px', background: '#0075BF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmCal()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )}
                            </Backdrop>

                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    flexWrap: 'wrap',
                                    gap: '10px'
                                }}>
                                    {this.state.options?.map((option: any) => (
                                        <Box
                                            key={option.value}
                                            sx={{
                                                display: 'inline-flex',
                                                border: this.state.selectedValue === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                                backgroundColor: this.state.selectedValue === option.value ? '#EFF8FB' : 'transparent',
                                                borderRadius: '32px',
                                                padding: '8px 12px',
                                                alignItems: 'center',
                                                transition: 'background-color 0.3s, border-color 0.3s',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={this.state.selectedValue === option.value}
                                                        onChange={this.handleChange}
                                                        value={option.value}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#00A7D1',
                                                            },
                                                            color: '#E3E3E3'
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                                }
                                            />
                                        </Box>
                                    ))}
                                </div>
                            </FormControl>

                            {this.state.selectedValue === 'other' && (
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                        type="text"
                                        data-test-id="otherinput"
                                        placeholder="Choose Day"
                                        value={this.state.selectedDate || ''}
                                        style={{
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            outline: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                        readOnly
                                    />
                                    <img 
                                      data-test-id="otherimage"
                                        src={calendar.default}
                                        alt="Open Calendar"
                                        style={{ cursor: 'pointer', width: '44px', height: '44px' }}
                                       
                                    />
                                </div>
                            )}
                        </div>

                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginBottom: '10px',
                            marginTop: '30px'
                        }}
                        > At what time you need to be reminded?</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                <input
                                    
                                    type="text"
                                    data-test-id="timeropen"
                                    placeholder="Select Time"
                                    value={timeSets}
                                    style={{
                                        width: '100%',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        border: 'none',
                                        outline: 'none',
                                        marginRight: '10px',
                                        backgroundColor: 'transparent',
                                    }}
                                    readOnly
                                />
                                <img
                                   src={time.default}
                                    alt="Open Calendar"
                                    data-test-id="timerimage"
                                    style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                    
                                />
                            </div>
                            {timeSets && (
                                <span style={{ color: '#189718', marginLeft: '10px', fontFamily: 'Chromatica-Regular',fontSize:'16px' }}>
                                    {`Great! We'll remind you at ${timeSets}`}
                                </span>
                            )}
                        </div>

                        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupTime(this.state.openTime)}>
                            {this.state.openTime && (
                                <Card style={{
                                    width: '328px', height: '450px', display: 'flex', flexDirection: 'column',
                                    borderRadius: '28px', padding: '25px 10px 15px 10px',
                                    gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                }}>
                                    <CardContent style={{
                                        display: 'flex', flexDirection: 'column',
                                        gap: '35px', alignItems: 'center', height: "415px"
                                    }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        </LocalizationProvider>
                                    </CardContent>
                                </Card>
                            )}
                        </Backdrop>
                        <div style={{ display: 'flex' }}>
                            <Checkbox
                                checked={checkedIte[1] || false}
                                onChange={() => this.handleCheckbox(1)}
                                sx={{
                                    marginLeft: '-15px',
                                    borderRadius: '6px',
                                    color: this.handleColor(checkedIte[1]),
                                    '&.Mui-checked': {
                                        color: '#00A7D1',
                                    },
                                }}
                            />
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '10px'
                            }}
                            >  Do you want to make it happen on a daily basis?</Typography>
                        </div>

                        <Button

                            variant="contained"
                            sx={{
                                padding: "10px",
                                borderRadius: "16px",
                                boxShadow: 'none',
                                background:
                                    "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                                textTransform: "none",
                                backgroundColor: '#EFF8FB',
                                fontSize: "18px",
                                lineHeight: "20px",
                                marginTop: '30px',
                                marginBottom: '30px',
                                cursor: 'pointer',
                                width: '348px',
                                height: '56px',
                                fontWeight: 400,
                                fontFamily: 'Chromatica-Bold',
                            }}
                            data-test-id="wellspring"
                        >
                            <span style={{ marginRight: '5px' }}>Go to Dashboard</span>
                            <img src={arrowright.default} />
                        </Button>
                        <Box
                            sx={{
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 4,
                            }}
                        >
                          
                        </Box>
                    </Box>
                    <Box
                                src={bottomBannerImg.default}
                                sx={{  
                                    width: '100%',
                                    bottom: 19,
                                    position: "absolute",
                                    height: 'auto',
                                    right: 0,                               
                                    maxWidth: '600px',
                                }}
                                alt="image" component="img"  
                              
                            />
                </Grid>
            </Grid>
        )
    }
}


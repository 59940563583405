export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgArrowLeft = require("../assets/arrow-left.png");
export const searchIcon = require("../assets/search-icon.png");
export const dropdownIcon = require("../assets/dropdown-icon.png");
export const deleteIcon = require("../assets/delete-icon.png");
export const editIcon = require("../assets/edit-icon.png");
export const calendarIcon = require("../assets/calendar-icon.png");
export const calendarColorIcon = require("../assets/calendar-color-icon.png");
export const classIcon = require("../assets/class-icon.png");
export const clockIcon = require("../assets/clock-icon.png");
export const goalIcon = require("../assets/goal-item.png");
export const presentationIcon = require("../assets/presentation-icon.png");
export const userIcon = require("../assets/user-icon.png");
export const background = require("../assets/background.png");
export const createGoalIcon = require("../assets/create-goal.png");
export const retrieveGoalIcon = require("../assets/retrieve-goal.png");
export const arrowFullRightIcon = require("../assets/arrow-full-right.png");

export const info = require("../assets/info.png");
export const backwardbutton = require("../assets/backwardbutton.png");
export const arrow = require("../assets/arrow.png");
export const three = require("../assets/three.png");
export const bottomBannerImg=require('../assets/bottomBannerImg.png');
export const time=require('../assets/time.png');
export const calendar=require('../assets/calendar.png')
export const arrowright=require('../assets/arrowright.png')
export const rightmovearrow = require("../assets/rightmovearrow.png");
export const menuAdded = require("../assets/hamburgermenu.png");

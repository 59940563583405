import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gridItems: any, 
  scrollOffset:number,
  checkedItems: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      scrollOffset: 0,
      gridItems: [], 
      checkedItems: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.fetchGridItems();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }
  loginPage=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  setGoals=()=>{
    this.props.navigation.navigate("Goals")
  }
  hamburgerPage=()=>{
    this.props.navigation.navigate("HamburgerMenu")
  }
  handleScrollRight = () => {
    this.setState((prevState) => ({
      scrollOffset: Math.min(prevState.scrollOffset + 5, this.state.gridItems.length - 1),
    }));
  };
  fetchGridItems = () => {
    const itemsFromApi = [
        { id: 1, label: 'Alcohal' },
        { id: 2, label: 'Cannabis' },
        { id: 3, label: 'Nicotinne' },
        { id: 4, label: 'C' },
        { id: 5, label: 'D' },
        { id: 6, label: 'E' },
        { id: 7, label: 'F' },
        { id: 8, label: 'G' },
        { id: 9, label: 'H' },
        { id: 10, label: 'I' },
        { id: 11, label: 'J' },
        { id: 12, label: 'K' },
    ];
    
    this.setState({ 
        gridItems: itemsFromApi 
    });
}
handleScrollLeft = () => {
  this.setState((prevState) => ({
    scrollOffset: Math.max(prevState.scrollOffset - 5, 0), 
  }));
};
handleCheckboxChange = (id:any) => {
  this.setState((prevState) => ({
      checkedItems: {
          ...prevState.checkedItems,
          [id]: !prevState.checkedItems[id],
      },
  }));
}
 
  // Customizable Area End
}
